<template>
	<section class="booking-section">
		<div class="reject">
			<div class="column-1">
				<h2 class="section-title">Отклонить бронирование</h2>
				<p class="reject-advice">
          Обсудите с гостем условия бронирования перед отклонением. Возможно, вы найдете компромисс.
				</p>
				<router-link
					to="/profile/chat"
					type="button"
					@click.prevent="handlerContactWithGuest(booking.user_id)"
					class="btn reject__chat-btn"
				>
					Написать гостю
				</router-link>
				<div class="reject-reasons">
					<h2 class="reject-reasons__title">
						Укажите причину отклонения заявки на бронирование
					</h2>

					<p class="reject-reasons__subtitle">Необязательные поля</p>

					<form @submit.prevent class="reject-reasons__form">
						<ul class="reject-reasons__list">
							<template
								v-for="item in $store.getters.ownerRejectTypes"
								:key="item.code"
							>
								<li v-if="!item.show_textarea" class="reject-reasons__item">
									<p class="reject-reasons__item_text">
										{{ item.text }}
									</p>
									<div class="reject-reasons__item_switcher switcher">
										<input
											class="tgl tgl-light"
											v-model="code"
											:value="item.code"
											name="reject-booking-reason"
											:id="`reject-booking-${item.code}`"
											type="radio"
										/>
										<label
											class="tgl-btn"
											:for="`reject-booking-${item.code}`"
										/>
									</div>
								</li>
								<label
									v-else
									:for="`reject-booking-${item.code}`"
									class="reject-reasons__item reject-reasons__item_select"
								>
									<input
										type="radio"
										hidden
										v-model="code"
										:id="`reject-booking-${item.code}`"
										:value="item.code"
										name="reject-booking-reason"
									/>
									<div class="select__wrapper">
										<div
											class="reject-reasons__item_text select__head"
											:class="{ active: code === item.code }"
											@click="toggle"
										>
											{{ item.text }} <tk-svg type="arrow-top" />
										</div>
										<transition name="slide-fade">
											<div class="select__textarea" v-if="code === item.code">
												<textarea
													name="another-teason-text"
													id="another-teason-text"
													v-model="message"
													placeholder="Текст"
												/>
												<!-- <button>Готово</button> -->
											</div>
										</transition>
									</div>
								</label>
							</template>
						</ul>
						<button :disabled="!code" class="btn reject-btn" @click="submit">
							Отклонить заявку на бронирование
						</button>
					</form>
				</div>
			</div>
			<div class="column-2">
				<GuestCard :content="guestData" />
			</div>
		</div>
	</section>
</template>

<script>
import GuestCard from '@/components/cards/BookingGuestCard.vue'

export default {
	name: 'BookingReject',
	components: { GuestCard },
  inject: ['chat'],
	watch: {
		code() {
			this.message = ''
		}
	},
	data() {
		return {
			active: false,
			code: null,
			message: null,
			user: {},
			booking: {}
		}
	},
	async created() {
		await this.$store.dispatch('getRejectAndComplaint')

		const response = await this.$api.reserve.getOwnerReserve({
			reserve_id: this.$route.params.id
		})

		if (response?.status) {
			this.booking = { ...response.response }

			const user = await this.$api.users.get({ ids: [this.booking.user_id] })

			if (user?.status) {
				this.user = user.response.users[0]
			}
		}
	},
	computed: {
		guestData() {
			return {
				...this.user,
				...this.booking
			}
		}
	},
	methods: {
    handlerContactWithGuest(id) {
      this.chat.getOrCreateConversationWithUser(id)

      this.$router.push('/profile/chat')
    },
		async submit() {
			this.$store.commit('showModal', {
				name: 'BookingRejectConfirmByOwner',
				props: {
					isConfirm: false,
					values: { ...this.guestData },
					callback: async (isConfirm = false) => {
						// true/false

						if (isConfirm) {
							const response = await this.$api.reserve.toggleBookingOwner({
								reserve_id: this.$route.params.id,
								reason_code: this.code,
								message: this.message
							})

							if (response?.status) {
								this.$store.commit('showModal', {
									name: 'CommonInfo',
									props: {
										text: `Заявка на бронирование отклонена! </br> Гостю будут возвращены деньги в соответствиии с условиями отмены бронирования.`
									}
								})

								this.$router.back()
							} else {
								this.$store.commit('showNotification', {
									type: 'success',
									text: 'Ошибка отмены заявки. Обратитесь к администратору'
								})
							}
						}
					}
				}
			})
		}
	}
}
</script>

<style scoped lang="sass">
.booking-section
	background-color: #ffffff
	padding-bottom: 251px
	box-sizing: border-box
	.reject
		position: relative
		display: flex
		width: 970px
		.column-1
			width: 310px
			margin-right: 110px
		.column-2
			width: 345px
		.section-title
			margin-bottom: 30px
		&-rules__title,
		&-disadvantages__title,
		&-advice
			font-size: 16px
			font-weight: 600
			line-height: 16px
			margin-bottom: 15px
		&-rules
			padding: 0 20px
			&__list
				padding-left: 10px
				margin-bottom: 15px
				list-style: none
			&__item
				position: relative
				font-size: 14px
				line-height: 16px
				&:not(:last-child)
					margin-bottom: 20px
				&:before
					content: ''
					position: absolute
					left: -10px
					top: 6px
					width: 6px
					height: 6px
					background: #1AC386
					border-radius: 1px
		&-disadvantages
			background: #F5F5F5
			border-radius: 12px
			padding: 22px 20px 39px
			margin-bottom: 27px
			&__list
				list-style: none
				padding-left: 10px
				margin-bottom: 0px
			&__item
				position: relative
				font-size: 14px
				line-height: 16px
				&:not(:last-child)
					margin-bottom: 20px
				&:before
					content: ''
					position: absolute
					left: -10px
					top: 6px
					width: 6px
					height: 6px
					background: #1AC386
					border-radius: 1px
		&-advice
			line-height: 20px
			padding: 0
			margin-bottom: 13px
		&__chat-btn
			display: flex
			justify-content: center
			align-items: center
			width: 310px
			height: 55px
			color: white
			margin-bottom: 30px
			background-color: #1AC386
			border-radius: 10px
		&-reasons
			padding: 0
			&__title
				font-size: 18px
				line-height: 20px
				margin-bottom: 18px
			&__subtitle
				font-size: 14px
				line-height: 14px
				color: #CBCBCB
			&__list
				list-style: none
				padding-left: 0
				margin-bottom: 36px
				background: #FFFFFF
				border-radius: 10px
				box-shadow: 0px 0px 17px -6px lightgrey
			&__item
				display: flex
				justify-content: space-between
				align-items: center
				padding: 14px 10px 14px 15px
				&:not(:last-child)
					border-bottom: 0.5px solid #E2E2E2
				&_text
					margin: 0
				&_select
					cursor: pointer
					.select__wrapper
						position: relative
						width: 100%
					.select__head
						display: flex
						justify-content: space-between
						align-items: center
						padding-right: 9px
						z-index: 4

						&.active .svg-icon
							transform: rotate(180deg)
							transition: all 0.3s linear
					.select__textarea
						width: 100%
						margin-top: 35px
						z-index: 2
						textarea
							display: block
							width: 100%
							height: 110px
							padding: 10px
							margin-bottom: 10px
							border: 1px solid #E2E2E2
							box-sizing: border-box
							border-radius: 12px
							caret-color: #1AC386
							resize: none
							&:focus
								outline: none
						button
							background: transparent
							border: none
							font-weight: 500
							font-size: 14px
							line-height: 16px
							color: #1AC386
		&-btn
			width: 310px
			height: 60px
			background: #fff
			border: 1px solid #E93030
			color: #E93030
			border-radius: 10px
			bottom: 20px
			&:hover
				background: #E93030
				color: #fff

// 1080
@media screen and (max-width: 1440px)
	.booking-section
		.reject
			width: 792px
			.column-1
				margin-right: 95px

// 996
@media screen and (max-width: 1180px)
	.booking-section
		.reject
			width: 728px
			.column-1
				margin-right: 30px

// 900
@media screen and (max-width: 1024px)
	.booking-section
		.reject
			width: 650px
			.column-1
				margin-right: 15px
// 768
@media screen and (max-width: 912px)
	.booking-section
		.reject
			width: 538px
			flex-wrap: wrap
			justify-content: center
			.column-1
				margin-bottom: 30px
				margin-right: 0
			.column-2
				display: flex
				justify-content: center

// 540
@media screen and (max-width: 800px)
	.booking-section
		.reject
			width: 100%
			.column-1,
			.column-2
				width: 100%
			&__chat-btn
				display: flex
				width: 90%
				min-width: 220px
				max-width: 320px
				margin-right: 20px
			&-btn
				width: 100%
</style>
